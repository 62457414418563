@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

// colors

$color-primary: #f72f3a;
$color-secondary: #355564;
$color-accent: #5D493A;
$color-grey: #E1DFDE;
$color-A28F81: #A28F81;
$color-BB9F88: #BB9F88;
$cream: #e9e4d0;
$grey: #586067;

// gradient
$gradient: linear-gradient(120deg, rgba(247, 47, 58, 0.6840162090226716) 0%, rgba(53, 85, 100, 0.7848565451571253) 67%);
$buy-gradient: linear-gradient(90deg, rgb(241, 186, 91) 0%, rgb(244, 169, 89) 50%, rgb(250, 140, 62) 100%);
$red-gradient: linear-gradient(315deg, rgba(214, 119, 125, 1) 0%, rgba(174, 42, 49, 1) 100%);
$blue-gradient: linear-gradient(315deg, rgba(87, 158, 224, 1) 0%, rgba(14, 87, 155, 1) 100%);

$blue-transparent-gradient: linear-gradient(315deg, rgba(87, 158, 224, 0.8) 0%, rgba(14, 87, 155, 0.9) 100%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$title-font: 'Playfair Display', serif;
$body-font: "Catamaran", sans-serif;
$font: "Figtree", sans-serif;