@import './variables.scss';
@import './animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #b9b9b9;
}

::-webkit-scrollbar-thumb {
    background: $color-secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-primary;
}




/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}


* {
    transition: .5s all ease;
    scroll-behavior: smooth;
    font-family: $font;

}

.title {
    text-align: center;
    margin-bottom: 5%;
    position: relative;

    span {
        background: #ebebeb;
        padding: 5px 10px;
        border-radius: 5px;
        text-transform: capitalize;
    }

    h4 {
        text-transform: capitalize;
        font-size: 50px;
        margin: 1% 0;
    }

    p {
        color: rgba($color: #000, $alpha: 0.6);
        width: 60%;
        margin: auto;
        font-size: 18px;
        font-weight: 400;
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
    // background: rgb(149, 107, 65);
    // background: linear-gradient(251deg, rgba(149, 107, 65, 1) 0%, rgba(44, 36, 23, 1) 82%);
    background: #fff;
    background: linear-gradient(315deg, rgba(87, 158, 224, 1) 0%, rgba(14, 87, 155, 1) 100%);

    .logo {
        width: 11%;
        clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%);
        background: #fff;
        padding: 1% 5%;

        img {
            width: 100%;
        }
    }

    .links {
        margin-left: auto;
        width: 50%;
        position: relative;

        a {
            // color: #fff;
            padding: 1% 4%;
            color: #fff !important;
            font-size: 20px;
            font-weight: bold;
            cursor: default;
            margin-right: 10px;
            border-radius: 40px;
            // background: $color-secondary;
            // background: rgba($color: $cream, $alpha: 0.4);

            &:hover {
                color: #fff;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
            }
        }

        .prod {
            position: absolute;
            top: 250%;
            left: 25%;
            width: 50%;
            display: flex;
            // justify-content: center;
            // align-items: center;
            flex-direction: column;
            background: #579ee0;
            border-radius: 10px;
            display: none;


            a {
                margin-top: 10px;
                padding: 20px 30px;
                text-align: left;
                border-radius: 0;
                background: transparent;

                &:hover {
                    box-shadow: none;
                    transform: translateX(30px);

                }
            }
        }
    }

    .menu {
        width: 40px;
        height: 40px;
        fill: #fff;
        display: none;
        margin-right: 10%;
        margin-left: auto;
    }

    #navbar-mobile {
        display: none;
        position: fixed;
        right: 0;
        top: 0;
        width: 60%;
        height: 100%;
        padding: 5%;
        background: $blue-gradient;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
        animation: slide-in-right .5s 1;

        .links {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 30%;

            a {
                margin-bottom: 10%;
                font-size: 20px;
                color: #000;
                padding-left: 0;
                font-weight: normal;
                background: transparent;

                &:hover {
                    box-shadow: none;
                }
            }

            .prod {
                width: 100%;
                top: 55%;
                left: 0;
                background: $color-accent;

                a {
                    font-size: 16px;
                    color: #000;
                    padding: 0;
                    margin-left: 20px;
                }
            }
        }

        .cross {
            width: 30px;
            height: 30px;
            padding: 2%;
            fill: #fff;
            margin-left: auto;
            position: absolute;
            top: 2%;
            right: 10%;
        }
    }

}


/*--------------------------------------------------------------
# Welcome Page
--------------------------------------------------------------*/


@keyframes move {
    from {
        transform: scale(1.4);
    }

    to {
        transform: scale(1.6);
    }
}


.whatsapp {
    position: fixed;
    z-index: 3000;
    right: 10px;
    bottom: 10px;

    svg {
        width: 90px;
        filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));
    }
}

.banner {
    position: relative;

    img {
        width: 100%;
    }

    a {
        width: 130px;
        text-align: center;
        background: $red-gradient;
        color: #fff;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 40px;
        padding: 10px 30px;
        font-size: 20px;
    }
}

section {
    padding: 5%;
    background: #fff;
}

.blog-page {


    .blog-body {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 5%;
        margin-bottom: 10%;

        .blog-card {
            width: calc(100% / 3.5);

            &:nth-child(2) {
                margin: 0 60px;
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 35vh;
            }

            h3 {
                margin: 5% 0;
                color: $color-accent;
                font-weight: bold;
            }

            p {
                color: $grey;

            }
        }
    }
}

.marquee {
    padding: 20px;
    background: linear-gradient(315deg, rgba(214, 119, 125, 1) 0%, rgba(174, 42, 49, 1) 100%);
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin: 0;
    font-weight: bold;
}

.about {
    // padding: 5%;
    // background: $color-primary;

    .about-body {
        display: flex;
        align-items: stretch;
        justify-content: space-between;


        .about-img {
            width: 50%;

            img {
                width: 100%;
                object-fit: cover;
            }

            .img1 {
                width: 65%;
                margin-left: 30%;
            }

            .img2 {
                width: 40%;
                margin-top: -40%;
            }
        }

        .about-text {
            width: 60%;
            padding-left: 3%;

            span {
                background: #ebebeb;
                padding: 5px 10px;
                border-radius: 5px;
            }

            h3 {
                text-transform: capitalize;
                font-size: 50px;
                margin: 1% 0;
            }


            a {
                padding: 10px 30px;
                color: #fff;
                border-radius: 5px;
                background: $color-primary;
                font-size: 20px;
                font-weight: bold;
                margin-top: 20px;
                display: block;
                text-align: center;
                width: 130px;
            }

            p,
            li {
                line-height: 180%;
                font-size: 18px;
                color: $grey;
                list-style-type: disc;
            }

            ul {
                padding-left: 5%;
            }
        }


    }
}

.why {
    padding: 5%;
    background: #f8f8f8;

    .why-body {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .why-card {
            padding: 20px;
            width: calc(100% / 5);
            margin: 10px;
            background: #579ee0;
            color: #fff;
            border-radius: 10px;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);


        }
    }
}

.products {
    // padding: 5%;
    // background: #F7F5EE;
    position: relative;
    background: rgb(188, 229, 255);
    background: linear-gradient(315deg, rgba(188, 229, 255, 1) 0%, rgba(226, 229, 255, 1) 100%);

    .products-body {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .products-card {
            width: calc(100% / 2);
            margin-right: 30px;
            // padding: 20px;
            color: #fff;
            position: relative;
            height: 70vh;


            img {
                width: 100%;
                border-radius: 30px;
                height: 100%;
                object-fit: cover;
            }

            .products-card-text {
                background: $blue-transparent-gradient;
                padding: 20px;
                clip-path: circle(53.5% at 54% 84%);
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                border-radius: 0 0 30px 30px;

                svg {
                    width: 60px;
                    height: 60px;
                    margin-left: 70%;
                    fill: #fff;
                    margin-top: 30%;
                }

                h3 {
                    font-weight: bold;
                    font-size: 25px;
                    margin-bottom: 3%;
                    margin-left: 15%;


                }

                p {
                    font-size: 16px;
                    color: rgba($color: #fff, $alpha: 0.6);
                    margin: 0;
                    width: 80%;
                    line-height: 140%;
                    margin-left: 15%;

                }

                a {
                    background: rgba($color: #fff, $alpha: 0.4);
                    border-radius: 5px;
                    padding: 10px 15px;
                    width: 150px;
                    backdrop-filter: blur(4px);
                    display: block;
                    margin-left: 15%;
                    text-align: center;
                    color: #000;
                    font-weight: bold;
                    margin-top: 20px;

                }

            }

        }



    }
}

.testimonial {
    margin-bottom: -10%;

    .testimonial-body {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .alice-carousel__next-btn,
        .alice-carousel__prev-btn {
            margin-top: -10%;
            cursor: pointer;

            p {
                font-size: 100px;
                color: $color-secondary;
            }
        }

        .testimonial-card {
            background: #f3f3f3;
            padding: 20px;
            width: 90%;
            margin-right: 20px;
            border-radius: 10px;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
            height: 30vh;
            margin: 3% 0;

            .testimonial-star {
                svg {
                    fill: #579ee0;
                    width: 30px;
                    height: 30px;
                    // background: #e7e7e7;
                    margin-right: 5px;
                    border-radius: 5px;
                    padding: 3px;
                }
            }
        }

        p {
            font-weight: 400;
            line-height: 150%;
            color: rgba($color: #000, $alpha: 0.7);
        }
    }
}

.numbers {
    background: linear-gradient(315deg, rgba(188, 229, 255, 1) 0%, rgba(226, 229, 255, 1) 100%);

    .numbers-body {
        display: flex;
        justify-content: center;
        align-items: stretch;

        .numbers-card {
            width: calc(100% / 3);
            text-align: center;

            svg {
                fill: #0364b8;
                width: 80px;
                height: 80px;
                margin: auto;
            }

            h3 {
                font-size: 50px;
                color: #0364b8;
                margin: 0;
            }

            p {
                font-size: 20px;
                text-transform: capitalize;
            }
        }
    }
}

.contact {
    background: linear-gradient(315deg, rgba(188, 229, 255, 1) 0%, rgba(226, 229, 255, 1) 100%);

    .contact-body {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .contact-left {
            width: 40%;

            h3 {
                font-size: 40px;
                margin: 0;
                margin-bottom: 20px;
            }

            p {
                line-height: 180%;
                color: rgba($color: #000, $alpha: 0.7);
            }

            .contact-left-cont {
                display: flex;
                align-items: center;
                padding: 20px;
                background: $blue-gradient;
                color: #fff;
                margin-bottom: 20px;
                border-radius: 10px;

                svg {
                    fill: #fff;
                    width: 60px;
                    height: 60px;
                    margin-right: 5%;
                }

                h3 {
                    font-size: 30px;
                    text-transform: capitalize;
                    margin: 0;
                    margin: 2% 0;
                }

                a {
                    color: rgba($color: #fff, $alpha: 0.7);
                    font-size: 18px;
                    line-height: 180%;
                }
            }
        }

        form {
            width: 55%;

            h3 {
                font-size: 40px;
                margin: 0;
                margin-bottom: 20px;
                padding-left: 10px;
            }

            p {
                line-height: 180%;
                color: rgba($color: #000, $alpha: 0.7);
                padding-left: 10px;

            }

            .form {
                display: flex;
                flex-wrap: wrap;
            }

            h2 {
                color: $color-primary;
                font-size: 2.5vw;
                text-align: center;
                text-transform: capitalize;
                margin-bottom: 5%;
            }

            input,
            select,
            textarea {
                background: #fff;
                border: 1px solid rgba($color: #000, $alpha: 0.2);
                border-radius: 5px;
                padding: 2% 3%;
                margin: 1% 0;
                outline: none;
                color: #000;
                font-size: 16px;
                width: calc(100%/2.4);
                margin-left: 10px;

                &::placeholder {
                    color: #000;
                }
            }

            select {
                width: calc(100%/2.1);

                option {
                    background: $color-secondary;
                    color: #fff;
                    padding: 20px;
                }
            }

            textarea {
                height: 20vh;
                width: 100%;
            }

            button {
                background: $blue-gradient;
                padding: 1% 3%;
                margin-top: 20px;
                border-radius: 5px;
                color: #fff;
                width: 200px;
                font-size: 25px;
                border: none;
                margin-left: 10px;

                &:hover {
                    background: $gradient;
                    color: #fff;
                }
            }
        }

    }


}

.page-banner {
    height: 50vh;
    background: #F5F3F4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;

    h3 {
        font-size: 60px;
        margin: 0;
        margin-bottom: 2%;
        color: #579ee0;
        text-align: center;
    }

    h6 {
        font-size: 25px;
        margin: 0;
        font-weight: bold;

        a {
            font-weight: normal;
        }
    }
}

footer {
    background: $blue-gradient;
    padding: 3% 5% 0 5%;
    color: #fff;
    position: relative;
    z-index: 2;


    .footer-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0%;
        flex-direction: column;

        .footer-about {
            width: 30%;

            img {
                width: 40%;
            }

            h2 {
                font-size: 25px;

                text-transform: uppercase;
                margin-top: 20px;
            }

            p {
                font-size: 16px;
                // text-align: center;
                margin-top: 5%;
            }
        }

        .footer-contact {
            width: 100%;
            display: flex;
            margin-bottom: 1%;

            h3 {
                font-weight: bold;
                margin-top: 0;
                font-size: 30px;
                margin-bottom: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;

                // letter-spacing: 1px;
                .svg {
                    width: 50px;
                    height: 50px;
                    margin-right: 2%;

                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: #fff;
                }
            }

            .footer-card {
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: calc(100% / 2);
                background: rgba($color: #000, $alpha: 0.5);
                border-radius: 20px;
                padding: 20px;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                margin-right: 10px;
                margin-bottom: 20px;
                backdrop-filter: blur(4px);

                &:last-child {
                    .footer-card-text {
                        flex-direction: column;

                        li,
                        p {
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }
                }

                .footer-card-text {

                    font-size: 18px;
                    font-weight: 100;
                    line-height: 200%;
                    display: flex;
                    // align-items: center;

                    h4 {
                        margin: 0;
                        text-align: left;
                    }

                    ul {
                        padding-left: 5%;
                        margin-top: 0;
                    }

                    li {
                        font-size: 18px;
                        list-style-type: disc;
                        text-transform: capitalize;
                        line-height: 120%;
                    }



                    a {
                        text-decoration: none;
                        font-size: 18px;
                    }
                }
            }
        }


        // .footer-location {
        //     padding: 0;

        //     h3 {
        //         font-weight: bold;
        //         margin-top: 0;
        //         font-size: 30px;
        //         margin-bottom: 20px;
        //         display: flex;
        //         align-items: flex-start;

        //         // letter-spacing: 1px;
        //         .svg {
        //             width: 50px;
        //             height: 50px;
        //             margin-right: 2%;

        //         }

        //         svg {
        //             width: 40px;
        //             height: 40px;
        //             fill: #fff;
        //         }
        //     }

        //     .footer-location-body {
        //         display: flex;
        //         justify-content: flex-start;
        //         align-items: stretch;

        //         .footer-card-text {

        //             font-size: 18px;
        //             font-weight: 100;
        //             line-height: 200%;
        //             display: flex;
        //             flex-direction: column;
        //             width: calc(100% / 2);
        //             // background: rgba($color: #000, $alpha: 0.7);
        //             // border-radius: 20px;
        //             // padding: 20px;
        //             // box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.4);
        //             // margin-right: 10px;
        //             // margin-bottom: 20px;
        //             // backdrop-filter: blur(4px);

        //             h4 {
        //                 margin: 0;
        //                 margin-bottom: 5px;
        //             }

        //             &:last-child {
        //                 ul {
        //                     display: flex;
        //                     padding-left: 0;

        //                     li {
        //                         width: calc(100% / 2);
        //                         list-style-type: none;
        //                         margin-right: 20px;
        //                     }
        //                 }
        //             }

        //             ul {
        //                 padding-left: 5%;
        //                 margin-top: 0;
        //             }

        //             li {
        //                 font-size: 18px;
        //                 list-style-type: disc;
        //                 text-transform: capitalize;
        //                 line-height: 120%;
        //                 margin-bottom: 10px;
        //             }



        //             a {
        //                 text-decoration: none;
        //                 font-size: 18px;
        //             }
        //         }
        //     }


        // }


    }

    .footer-copyright {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.6);
        // margin-top: 2%;
        padding: 2%;
        display: flex;

        p {
            margin: 0;
        }

    }

}

/*--------------------------------------------------------------
# Product Pages
--------------------------------------------------------------*/

.about-page {
    .about {
        .about-text {

            p,
            li {
                font-size: 16px;
                line-height: 140%;
            }
        }

    }

    .strength {
        .strength-body {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;

            .strength-card {
                width: calc(100% / 3.9);
                margin: 10px;
                padding: 20px;
                background: $blue-gradient;
                color: #fff;
                border-radius: 10px;

                svg {
                    fill: #579ee0;
                    width: 50px;
                    height: 50px;
                }

                h3 {
                    font-size: 25px;
                    text-align: center;
                }

                p {
                    color: rgba($color: #fff, $alpha: 0.7);
                    line-height: 180%;
                }
            }
        }
    }

    .aim {
        display: flex;
        justify-content: center;
        align-items: stretch;

        img {
            width: calc(100%/3);
            object-fit: cover;
            margin: 0 40px;
            height: 70vh;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
            border-radius: 10px;
        }

        .aim-card {
            width: calc(100% / 3);

            .arrow {
                object-fit: contain;
                box-shadow: none;
                width: 80%;
                margin-top: -40%;
                margin-left: 60%;
                transform: scaleX(-1);
            }

            h3 {
                font-size: 50px;
                margin: 0;
            }

            p {
                color: rgba($color: #000, $alpha: 0.7);
                line-height: 180%;
            }
        }

        .card2 {
            margin-top: -10px;

            .arrow {
                object-fit: contain;
                box-shadow: none;
                width: 80%;
                margin-top: -40%;
                margin-left: -30%;
                margin-bottom: -30%;
                transform: scaleY(-1);
            }


        }
    }
}

.contact-page {
    .contact {
        background: #fff;

        .contact-body {
            .contact-left {
                .contact-left-cont {
                    background: transparent;
                    color: #000;
                    border: 1px solid rgba($color: #000, $alpha: 0.2);
                    position: relative;
                    margin-top: 50px;

                    svg {
                        fill: #579ee0;
                    }

                    a {
                        color: #000;
                        font-size: 16px;
                    }

                    span {
                        background: #fff;
                        position: absolute;
                        top: -10px;
                        left: 5%;
                        padding: 0 40px;
                    }

                    h3 {
                        font-size: 20px;
                    }

                    ol {
                        li {
                            color: rgba($color: #000, $alpha: 0.7);
                            font-size: 16px;
                            list-style-type: unset;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            form {
                margin-top: 50px;
            }
        }
    }
}

.recruitment {
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: stretch;

    img {
        width: 35%;
        margin-right: 40px;
        object-fit: cover;
        border-radius: 10px;
    }

    .recruitment-body {
        width: 65%;

        p {
            color: rgba($color: #000, $alpha: 0.7);
            line-height: 180%;
        }

        ol {
            li {
                list-style-type: unset;
                color: rgba($color: #000, $alpha: 0.7);
                line-height: 180%;
                font-weight: bold;
                font-size: 20px;
                width: calc(100% / 1.4);
                margin: 0 30px;

            }

            p {
                margin: 0;
                font-size: 16px;
                font-weight: normal;
            }

            display: flex;
        }

        ul {

            li {
                list-style-type: unset;
                color: rgba($color: #000, $alpha: 0.7);
                line-height: 200%;
                font-weight: normal;
                font-size: 16px;

            }
        }
    }

}

.recruitment-page {

    .why {
        padding: 5%;
        background: #f8f8f8;

        .why-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .why-card {
                padding: 20px;
                width: calc(100% / 4);
                margin: 10px;
                background: linear-gradient(315deg, rgba(188, 229, 255, 1) 0%, rgba(226, 229, 255, 1) 100%);
                color: #000;
                border-radius: 10px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);

                p {
                    line-height: 150%;
                    font-size: 14px;
                }
            }
        }
    }



    .recruitment-banner,
    .temporary-banner {
        padding: 2% 5% 5% 5%;
        text-align: center;
        background: url("../Image/Recruitment/banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        color: #000;
        height: 60vh;

        h3 {
            font-size: 50px;
            margin: 0;
        }

        p {
            color: rgba($color: #000, $alpha: 0.8);
            font-size: 20px;
            width: 80%;
            margin: 20px auto 50px auto;
        }

        a {
            background: $blue-gradient;
            padding: 10px 20px;
            border: 1px solid #fff;
            border-radius: 5px;
            color: #fff;
        }


        .recruitment-banner-card {
            background: rgba($color: #fff, $alpha: 0.4);
            backdrop-filter: blur(2px);
            padding: 40px 20px;
            border-radius: 20px;
            width: 80%;
            margin: 5% auto 0 auto;

        }
    }

    .temporary-banner {
        background: url("../Image/1.png");
        background-size: cover;
        background-repeat: no-repeat;

    }

    $primary-color: #003c71;
    $secondary-color: #fff;

    .pro {
        padding: 5%;
        // background: #f8f8f8;

        .process {
            $bem-block: &;
            width: 100%;
            padding: 0 15px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;

            &__item {
                user-select: none;
                text-align: center;
                position: relative;
                padding: 15px 35px;
                transition: 0.4s ease-in-out;

                &:hover {
                    background: darken($secondary-color, 5%);
                }

                &:hover {
                    .process__number {
                        transform: translateY(5px);
                        color: $primary-color;
                    }
                }
            }

            &__number {
                font-size: 90px;
                -webkit-text-stroke: 1px $primary-color;
                display: block;
                color: transparent;
                font-weight: 700;
                transition: 0.4s ease-in-out;
            }

            &__title {
                display: block;
                font-weight: 700;
                letter-spacing: 1.5px;
                font-size: 20px;
                color: $primary-color;
                text-transform: uppercase;
                margin-top: 30px;
            }

            &__subtitle {
                display: block;
                font-size: 16px;
                color: darken($secondary-color, 45%);
                margin-top: 30px;
                line-height: 1.5;
            }
        }

        @media (min-width: 768px) {
            .process {
                display: inline-block;

                &__item {
                    width: 49%;
                    display: inline-block;
                }
            }
        }

        @media (min-width: 1200px) {
            .process {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__item {
                    width: 100%;

                    &:not(:last-of-type) {
                        &::after {
                            content: "";
                            width: 1px;
                            height: 75%;
                            background: darken($secondary-color, 45%);
                            position: absolute;
                            right: 0;
                            top: 50%;
                            opacity: 0.2;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
}

.tour-page {
    .tour {
        position: relative;

        img {
            width: 100%;
        }


        .alice-carousel__next-btn {
            position: absolute;
            top: 50%;
            right: 0%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));

            svg {
                width: 50px;
                height: 50px;
                fill: #fff;
            }
        }

        .alice-carousel__prev-btn {
            position: absolute;
            top: 45%;
            left: 2%;
            transform: translate(-50%, -50%);
            transform: scaleX(-1);
            // border: 2px solid #000;
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));
            width: 50px;
            height: 50px;

            svg {
                width: 50px;
                height: 50px;
                fill: #fff;
            }
        }

        .tour-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            backdrop-filter: blur(2px);
            background: rgba($color: #000, $alpha: 0.3);
            padding: 20px;
            border-radius: 20px;

            h3 {
                font-size: 70px;
                margin: 0;
                text-shadow: 1px 1px 1px #000;
            }

            p {
                font-size: 20px;
                text-shadow: 1px 1px 1px #000;
            }
        }
    }

    .tour-about {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 5%;

        img {
            width: 40%;
        }

        .tour-about-text {
            margin-right: 30px;

            h3 {
                font-size: 80px;
                margin: 0;
            }

            p {
                font-size: 22px;
                line-height: 200%;
            }
        }
    }

    .tour-service {
        background: #f8f8f8;
        padding: 5%;

        .tour-service-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .tour-service-card {
                width: calc(100% / 2);
                padding: 20px;
                margin: 10px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
                border: 1px solid rgba($color: #000, $alpha: 0.2);

                img {
                    width: 100%;
                    border-radius: 10px;
                }

                h3 {
                    font-size: 22px;
                    text-transform: capitalize;
                    // font-weight: 400;
                    text-align: center;
                }

            }
        }

    }

    .tour-why {

        padding: 5%;

        .tour-why-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .tour-why-card {
                width: calc(100% / 4);
                padding: 20px;
                margin: 10px;

                svg {
                    fill: #579ee0;
                    width: 70px;
                    height: 70px;
                }

                h3 {
                    font-size: 22px;
                }

                p {
                    color: rgba($color: #000, $alpha: 0.7);
                    line-height: 180%;
                }
            }
        }
    }

    .tour-banner {
        padding: 5%;
        text-align: center;
        background: url("../Image/Tour-and-Travels/banner.jpg");
        background-size: cover;
        background-position: 10% 70%;
        background-repeat: no-repeat;
        color: #fff;
        height: 60vh;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color: #000, $alpha: 0.5);
            z-index: 2;
        }

        h3 {
            font-size: 60px;
            margin: 0;
            text-shadow: 1px 1px 1px #000;
            margin-bottom: 5%;
            margin-top: 5%;
            position: relative;
            z-index: 3;
        }


        a {
            background: $blue-gradient;
            padding: 10px 20px;
            border: 1px solid #fff;
            border-radius: 5px;
            color: #fff;
            font-size: 20px;
            position: relative;
            z-index: 3;
        }
    }
}


.chaueffer-page {

    .chaueffer-slide {
        position: relative;

        img {
            width: 100%;
            height: 80vh;
            object-fit: cover;
        }

        .chaueffer-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;

            h3 {
                font-size: 90px;
                margin: 0;
                text-shadow: 1px 1px 1px #000;
            }

            p {
                font-size: 25px;
                text-shadow: 1px 1px 1px #000;

            }
        }
    }


    .why {
        padding: 5%;
        background: #f8f8f8;

        .why-body {
            display: flex;
            justify-content: center;
            align-items: stretch;

            .why-card {
                padding: 20px;
                width: calc(100% / 4);
                margin: 10px;
                background: linear-gradient(315deg, rgba(188, 229, 255, 1) 0%, rgba(226, 229, 255, 1) 100%);
                color: #000;
                border-radius: 10px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);

                svg {
                    fill: #000;
                    width: 50px;
                    height: 50px;
                }

                h3 {
                    font-size: 22px;
                }

            }
        }
    }

    .chaueffer-banner {
        padding: 2% 5% 5% 5%;
        text-align: center;
        background: url("../Image/driver/banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80vh;
        backdrop-filter: blur(3px);

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color: #000, $alpha: 0.5);
            z-index: 2;
        }

        h3 {
            font-size: 60px;
            margin: 0;
            margin-bottom: 30px;
            text-shadow: 1px 1px 1px #000;
            position: relative;
            z-index: 3;
        }

        a {
            background: $blue-gradient;
            padding: 10px 20px;
            border: 1px solid #fff;
            border-radius: 5px;
            font-size: 25px;
            position: relative;
            z-index: 3;
        }
    }

}

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .page-banner {
        height: 40vh;

        h3 {
            font-size: 30px;
        }

        h6 {
            font-size: 20px;
        }
    }

    .title {
        h4 {
            font-size: 20px;
            margin: 30px 0 10px 0;
        }

        p {
            font-size: 16px;
            width: 100%;
        }
    }

    header {
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        // padding: 3%;

        .logo {
            width: 35%;
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        margin-top: 15%;

        a {
            // width: 30px;
            // font-size: 10px;
            // padding: 5px;
            display: none;
        }

        img {
            height: 30vh;
            object-fit: cover;
        }
    }

    .vcm {
        .about {
            .about-body {
                flex-direction: column;

                .about-img,
                .about-text {
                    width: 100%;
                    margin-top: 5%;

                }



                .about-text {

                    h3 {
                        font-size: 30px;
                        margin-top: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        // width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .fssai {
                        margin-left: 0;
                        flex-direction: column;

                        .fssai-body {
                            width: 100%;
                        }
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .products {
            .products-body {
                flex-direction: column;

                .products-card {
                    width: 100%;
                    margin-bottom: 5%;
                    margin-right: 0;

                    .products-card-text {
                        padding: 250px 20px 20px 20px;

                        h3 {
                            font-size: 20px;
                            margin-left: 0;
                        }

                        p {
                            font-size: 14px;
                            margin-left: 0;
                        }

                        a {
                            margin-left: 0;
                        }

                        svg {
                            margin-top: 30%;
                        }
                    }

                }
            }
        }

        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 80%;
                }
            }
        }

        .numbers {
            .numbers-body {
                flex-direction: column;

                .numbers-card {
                    width: 100%;

                    svg {
                        width: 50px;
                        height: 50px;
                    }

                    h3 {
                        font-size: 30px;
                    }
                }
            }
        }

        .testimonial {
            .testimonial-body {
                flex-direction: column;

                .alice-carousel__prev-btn,
                .alice-carousel__next-btn {
                    margin-top: -130px;
                    margin-bottom: -40px;

                    p {
                        font-size: 70px;
                    }
                }

                .testimonial-card {
                    width: 85%;
                    margin-bottom: 30px;
                    height: 100%;
                }
            }

        }

        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }
                }

                form {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }

                    input,
                    select {
                        width: 100%;
                    }
                }
            }
        }


    }

    .about-page {
        .about {
            .about-body {
                flex-direction: column;

                .about-img {
                    width: 100%;
                }

                .about-text {
                    width: 100%;

                    h3 {
                        font-size: 25px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .strength {
            .strength-body {
                flex-direction: column;

                .strength-card {
                    width: 90%;
                    margin: 0;
                    margin-bottom: 10px;

                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }

        .aim {
            flex-direction: column;
            overflow: hidden;

            .aim-card {
                width: 100%;

                .arrow {
                    margin-left: 20%;
                    margin-bottom: -50%;
                    margin-top: -40%;
                }
            }

            img {
                width: 100%;
                margin: 0;
            }

            .card2 {

                h3 {
                    margin-top: 10%;

                }
            }
        }
    }
    .recruitment {
        flex-direction: column;

        img,
        .recruitment-body {
            width: 100%;
        }

        .recruitment-body{
            ol{
                flex-direction: column;
                padding-left: 0;
                li{
                    width: 90%;
                }
            }
        }
    }

    .recruitment-page {
      
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .recruitment-banner,
        .temporary-banner {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }

            .recruitment-banner-card {
                margin-top: 15%;
            }
        }
    }

    .chaueffer-page {
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .chaueffer-slide {
            .chaueffer-text {
                background: rgba($color: #000, $alpha: 0.4);
                border-radius: 20px;
                width: 80%;
                padding: 20px;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .chaueffer-banner {
            background-position: 40% 40%;

            h3 {
                font-size: 30px;
            }
        }
    }

    .contact-page {
        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;
                }

                form {
                    width: 100%;

                    input,
                    select {
                        width: 100%;
                    }
                }
            }


        }
    }

    footer {
        .footer-top {
            .footer-contact {
                flex-direction: column;

                h3 {
                    font-size: 25px;

                    .svg {
                        width: 30px;
                        height: 30px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .footer-card {
                    width: 100%;
                    margin-bottom: 5%;
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.4);
                    padding-top: 10px;
                    width: 90%;

                    &:first-child {
                        border: none;
                    }
                }
            }

        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .page-banner {
        height: 40vh;

        h3 {
            font-size: 40px;
        }

        h6 {
            font-size: 20px;
        }
    }

    .title {
        h4 {
            font-size: 30px;
        }

        p {
            font-size: 16px;
        }
    }

    header {
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        // padding: 3%;

        .logo {
            width: 20%;
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        // height: 60vh; 
        margin-top: 10%;

        a {
            padding: 5px;
            font-size: 16px;
        }
    }

    .vcm {
        .about {
            .about-body {
                flex-direction: column;

                .about-img,
                .about-text {
                    width: 100%;
                    margin-top: 5%;

                }



                .about-text {

                    h3 {
                        font-size: 30px;
                        margin-top: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        // width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .fssai {
                        margin-left: 0;
                        flex-direction: column;

                        .fssai-body {
                            width: 100%;
                        }
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .products {
            .products-body {
                flex-direction: column;

                .products-card {
                    width: 100%;
                    margin-bottom: 5%;
                    margin-right: 0;

                    .products-card-text {
                        padding: 100px 20px 20px 20px;

                        h3 {
                            font-size: 20px;
                            // margin-left: 0;
                        }

                        p {
                            font-size: 14px;
                            // margin-left: 0;
                        }

                        // a {
                        //     margin-left: 0;
                        // }

                        svg {
                            margin-top: 30%;
                        }
                    }

                }
            }
        }

        .why {
            .why-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .why-card {
                    width: 40%;
                }
            }
        }

        .numbers {
            .numbers-body {
                // flex-direction: column;

                .numbers-card {
                    // width: 100%;

                    svg {
                        width: 50px;
                        height: 50px;
                    }

                    h3 {
                        font-size: 30px;
                    }
                }
            }
        }

        .testimonial {
            .testimonial-body {
                flex-direction: column;

                .alice-carousel__prev-btn,
                .alice-carousel__next-btn {
                    margin-top: -130px;
                    margin-bottom: -40px;

                    p {
                        font-size: 70px;
                    }
                }

                .testimonial-card {
                    width: 85%;
                    margin-bottom: 30px;
                    // height: 100%;
                    height: 50vh;
                }
            }

        }

        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }
                }

                form {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }

                    input,
                    select {
                        width: 100%;
                    }
                }
            }
        }


    }

    .about-page {
        .about {
            .about-body {
                flex-direction: column;

                .about-img {
                    width: 100%;
                }

                .about-text {
                    width: 100%;

                    h3 {
                        font-size: 25px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .strength {
            .strength-body {
                // flex-direction: column;

                .strength-card {
                    width: 40%;
                    margin: 10px;

                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }

        .aim {
            flex-direction: column;
            overflow: hidden;

            .aim-card {
                width: 100%;

                .arrow {
                    margin-left: 20%;
                    margin-bottom: -50%;
                    margin-top: -60%;
                    width: 70%;
                }
            }

            img {
                width: 100%;
                margin: 0;
                margin-top: 20%;
                margin-bottom: 20%;
            }

            .card2 {

                h3 {
                    margin-top: 10%;

                }

                .arrow {
                    margin-left: 20%;
                    margin-bottom: -20%;
                    margin-top: -60%;
                    width: 70%;
                }
            }
        }
    }
    .recruitment {
        flex-direction: column;

        img,
        .recruitment-body {
            width: 100%;
        }

        .recruitment-body{
            ol{
                flex-direction: column;
                padding-left: 0;
                li{
                    width: 90%;
                }
            }
        }
    }

    .recruitment-page {
      
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .recruitment-banner,
        .temporary-banner {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }

            .recruitment-banner-card {
                margin-top: 15%;
            }
        }
    }

    .chaueffer-page {
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .chaueffer-slide {
            .chaueffer-text {
                background: rgba($color: #000, $alpha: 0.4);
                border-radius: 20px;
                width: 80%;
                padding: 20px;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .chaueffer-banner {
            background-position: 40% 40%;

            h3 {
                font-size: 30px;
            }
        }
    }

    .contact-page {
        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;
                }

                form {
                    width: 100%;

                    input,
                    select {
                        width: 100%;
                    }
                }
            }


        }
    }

    footer {
        .footer-top {
            .footer-contact {
                flex-direction: column;

                h3 {
                    font-size: 25px;

                    .svg {
                        width: 30px;
                        height: 30px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .footer-card {
                    width: 100%;
                    margin-bottom: 5%;
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.4);
                    padding-top: 10px;
                    width: 90%;

                    &:first-child {
                        border: none;
                    }
                }
            }

        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .page-banner {
        height: 40vh;

        h3 {
            font-size: 40px;
        }

        h6 {
            font-size: 20px;
        }
    }

    .title {
        h4 {
            font-size: 30px;
        }

        p {
            font-size: 16px;
        }
    }

    header {
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5) !important;
        // padding: 3%;

        .logo {
            width: 25%;
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        // height: 60vh; 
        margin-top: 10%;

        a {
            padding: 5px;
            font-size: 16px;
        }
    }

    .vcm {
        .about {
            .about-body {
                flex-direction: column;

                .about-img,
                .about-text {
                    width: 100%;
                    margin-top: 5%;

                }



                .about-text {

                    h3 {
                        font-size: 30px;
                        margin-top: 20px;
                    }

                    p {
                        font-size: 16px;
                    }

                    a {
                        // width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                    }

                    .fssai {
                        margin-left: 0;
                        flex-direction: column;

                        .fssai-body {
                            width: 100%;
                        }
                    }

                    .about-points {
                        li {
                            .pt {
                                h6 {
                                    font-size: 18px;
                                }

                                p {
                                    font-size: 14px;
                                }
                            }

                            .svg {
                                width: 20px;
                                height: 20px;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .products {
            .products-body {
                flex-direction: column;

                .products-card {
                    width: 100%;
                    margin-bottom: 5%;
                    margin-right: 0;

                    .products-card-text {
                        padding: 250px 20px 20px 20px;

                        h3 {
                            font-size: 20px;
                            margin-left: 0;
                        }

                        p {
                            font-size: 14px;
                            margin-left: 0;
                        }

                        a {
                            margin-left: 0;
                        }

                        svg {
                            margin-top: 30%;
                        }
                    }

                }
            }
        }

        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 90%;
                }
            }
        }

        .numbers {
            .numbers-body {
                flex-direction: column;

                .numbers-card {
                    width: 100%;

                    svg {
                        width: 50px;
                        height: 50px;
                    }

                    h3 {
                        font-size: 30px;
                    }
                }
            }
        }

        .testimonial {
            .testimonial-body {
                flex-direction: column;

                .alice-carousel__prev-btn,
                .alice-carousel__next-btn {
                    margin-top: -130px;
                    margin-bottom: -40px;

                    p {
                        font-size: 70px;
                    }
                }

                .testimonial-card {
                    width: 85%;
                    margin-bottom: 30px;
                    height: 100%;
                }
            }

        }

        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }
                }

                form {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                    }

                    input,
                    select {
                        width: 100%;
                    }
                }
            }
        }


    }

    .about-page {
        .about {
            .about-body {
                flex-direction: column;

                .about-img {
                    width: 100%;
                }

                .about-text {
                    width: 100%;

                    h3 {
                        font-size: 25px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .strength {
            .strength-body {
                flex-direction: column;

                .strength-card {
                    width: 90%;
                    margin: 0;
                    margin-bottom: 10px;

                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }

        .aim {
            flex-direction: column;
            overflow: hidden;

            .aim-card {
                width: 100%;

                .arrow {
                    margin-left: 20%;
                    margin-bottom: -50%;
                    margin-top: -40%;
                }
            }

            img {
                width: 100%;
                margin: 0;
            }

            .card2 {

                h3 {
                    margin-top: 10%;

                }
            }
        }
    }
    .recruitment {
        flex-direction: column;

        img,
        .recruitment-body {
            width: 100%;
        }

        .recruitment-body{
            ol{
                flex-direction: column;
                padding-left: 0;
                li{
                    width: 90%;
                }
            }
        }
    }

    .recruitment-page {
      
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .recruitment-banner,
        .temporary-banner {
            h3 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }

            .recruitment-banner-card {
                margin-top: 15%;
            }
        }
    }

    .chaueffer-page {
        .why {
            .why-body {
                flex-direction: column;

                .why-card {
                    width: 85%;
                }
            }
        }

        .chaueffer-slide {
            .chaueffer-text {
                background: rgba($color: #000, $alpha: 0.4);
                border-radius: 20px;
                width: 80%;
                padding: 20px;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .chaueffer-banner {
            background-position: 40% 40%;

            h3 {
                font-size: 30px;
            }
        }
    }

    .contact-page {
        .contact {
            .contact-body {
                flex-direction: column;

                .contact-left {
                    width: 100%;
                }

                form {
                    width: 100%;

                    input,
                    select {
                        width: 100%;
                    }
                }
            }


        }
    }

    footer {
        .footer-top {
            .footer-contact {
                flex-direction: column;

                h3 {
                    font-size: 25px;

                    .svg {
                        width: 30px;
                        height: 30px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .footer-card {
                    width: 100%;
                    margin-bottom: 5%;
                    border-top: 1px solid rgba($color: #fff, $alpha: 0.4);
                    padding-top: 10px;
                    width: 90%;

                    &:first-child {
                        border: none;
                    }
                }
            }

        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}


@media (min-width: 768px) and (max-width: $lg) {

    p,
    a,
    li {
        font-size: 16px;
    }

    header {
        // padding: 3%;

        .logo {
            width: 15%;
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .products {
        .products-body {
            .products-card {
                .products-card-text {
                    padding-top: 140px;

                    svg {
                        margin-top: 70%;
                        width: 40px;
                        height: 40px;
                    }

                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    footer {
        .footer-top {

            .footer-subscribe {

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

            }

        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}